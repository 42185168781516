import React, {Component} from 'react';
import ReactDOM from 'react-dom';
// import {hydrate, render} from "react-dom";

import registerServiceWorker from './registerServiceWorker';
class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            module: null
        };
    }

    /**
     * During mount, dynamically fetch ComponentB.
     *
     * @memberof App
     */
    componentWillMount() {
        import ('src/App').then((module) => this.setState({module: module.default}));
        import ('styles/main.scss').then(() => console.log('Styles loaded')).catch(err => console.error(err));
    }

    /**
     * Render App component.
     *
     * @memberof App
     * @returns {Object} component Basic div with dynamically loaded child
     */
    render() {
        const {module: App} = this.state;
        return (
            <React.Fragment>
                {App && <App/>}
            </React.Fragment>
        );
    }
}

// const rootElement = document.getElementById("root"); if
// (rootElement.hasChildNodes()) {     hydrate(         <App/>, rootElement); }
// else {     render(         <App/>, rootElement); }

ReactDOM.render(
    <App/>, document.getElementById('root'));
registerServiceWorker();
